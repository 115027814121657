// extracted by mini-css-extract-plugin
export var galleryMasonryImageWrapper = "q_jP d_jP d_ct";
export var galleryMasonryImage = "q_jN d_jN d_v d_bQ d_dz";
export var alignLeft = "q_pK d_fn d_bF d_dt";
export var alignCenter = "q_bN d_fp d_bC d_dv";
export var alignRight = "q_pL d_fq d_bG d_dw";
export var galleryContainer = "q_q3 d_dV";
export var galleryContainerFull = "q_q4 d_dS";
export var galleryRowWrapper = "q_q5 d_cb";
export var galleryGuttersImage = "q_jR d_jR d_J d_ct";
export var galleryNoGuttersImage = "q_jQ d_jQ d_J d_cC";
export var galleryTextGutters = "q_jL d_jL d_cv";
export var galleryTextNoGutters = "q_jM d_jM d_cv";
export var galleryTextMasonry = "q_q6 d_jL d_cv";
export var galleryImageWrapper = "q_q7 d_ff d_Y";
export var descText = "q_q8 d_jS d_Z d_8 d_6 d_7 d_m";
export var guttersDesc = "q_q9";