// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_rm d_gt d_cr";
export var heroHeaderCenter = "s_gv d_gv d_cr d_dv";
export var heroHeaderRight = "s_gw d_gw d_cr d_dw";
export var heroParagraphLeft = "s_rn d_gq d_cv";
export var heroParagraphCenter = "s_gr d_gr d_cv d_dv";
export var heroParagraphRight = "s_gs d_gs d_cv d_dw";
export var heroBtnWrapperLeft = "s_rp d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "s_rq d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "s_rr d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "s_rs d_gp d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "s_rt d_gn d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "s_rv w_rv";
export var heroExceptionNormal = "s_rw w_rw";
export var heroExceptionLarge = "s_rx w_rx";
export var Title1Small = "s_ry w_ry w_rL w_rM";
export var Title1Normal = "s_rz w_rz w_rL w_rN";
export var Title1Large = "s_rB w_rB w_rL w_rP";
export var BodySmall = "s_rC w_rC w_rL w_r3";
export var BodyNormal = "s_rD w_rD w_rL w_r4";
export var BodyLarge = "s_rF w_rF w_rL w_r5";