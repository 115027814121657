// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "x_fP d_fP d_by d_bG d_bH";
export var navbarDividedRight = "x_fQ d_fQ d_by d_bH";
export var menuDesign6 = "x_sT d_fL d_by d_dv d_bN d_bH";
export var menuDesign7 = "x_sV d_fL d_by d_dv d_bN d_bH";
export var menuRight = "x_sW d_fL d_by d_dv d_bN d_bH";
export var menuLeft = "x_sX d_fL d_by d_dv d_bN d_bH";
export var menuCenter = "x_sY d_fM d_fL d_by d_dv d_bN d_v d_bC d_bH";
export var menuDivided = "x_p5 d_fM d_fL d_by d_dv d_bN d_v d_bC";
export var menuDesign5 = "x_qg d_fN d_fL d_by d_dv d_bN d_bH";
export var isBurger = "x_sZ";
export var navbarItem = "x_p6 d_bw";
export var navbarLogoItemWrapper = "x_fX d_fX d_bB d_bN";
export var burgerToggleVisibleOpen = "x_s0 d_gc d_bx d_Y d_br";
export var burgerToggleVisible = "x_s1 d_gc d_bx d_Y d_br";
export var burgerToggle = "x_s2 d_gc d_bx d_Y d_br d_Y";
export var burgerToggleOpen = "x_s3 d_gc d_bx d_Y d_br";
export var burgerInput = "x_s4 d_f1 d_v d_G d_bx d_cb d_dk d_bc d_Z d_7 d_6 d_3 d_8";
export var burgerIcon = "x_s5 d_f2 d_v d_G";
export var burgerLine = "x_s6 d_f0";
export var burgerMenuDesign6 = "x_s7 d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign7 = "x_s8 d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuLeft = "x_s9 d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign5 = "x_tb d_f7 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuRight = "x_tc d_f7 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuCenter = "x_td d_f8 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDivided = "x_tf d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var secondary = "x_tg d_bB d_bN";
export var staticBurger = "x_th";
export var menu = "x_tj";
export var navbarDividedLogo = "x_tk";
export var nav = "x_tl";