// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "r_rb d_bC";
export var storyRowWrapper = "r_hw d_hw d_bJ";
export var storyLeftWrapper = "r_rc d_by d_bN";
export var storyWrapperFull = "r_rd d_cC";
export var storyWrapperFullLeft = "r_nM d_cC d_by d_bN d_bC";
export var contentWrapper = "r_n9 d_hx";
export var storyLeftWrapperCenter = "r_rf d_hy d_by d_bC d_bN d_bH";
export var storyRightWrapperCenter = "r_rg d_hD";
export var storyHeader = "r_rh d_hC d_v d_cr";
export var storyHeaderCenter = "r_hB d_hB d_v d_cr d_dv d_bx";
export var storyParagraphCenter = "r_hz d_hz d_bx d_dv";
export var storyBtnWrapper = "r_rj d_d1 d_d0 d_v d_by d_bF";
export var storyBtnWrapperCenter = "r_hH d_hH d_d0 d_v d_by d_bC";
export var imageWrapper = "r_rk d_ff d_Y";
export var imageWrapperFull = "r_rl d_v d_G d_bd d_Y";